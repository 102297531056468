import { Fragment } from "react";

import { useRouter } from "next/router";

import { Menu, Transition } from "@headlessui/react";
import classNames from "classnames";

import type { ThemeType } from "hooks/useThemeStore";
import { useThemeStore } from "hooks/useThemeStore";

import { SvgChevron, SvgGhostIcon } from "assets/svg";

export function getActiveAgeCategory(theme: ThemeType) {
  switch (theme) {
    case "blue":
      return "4-7 jaar";
    case "green":
      return "7-10 jaar";
    case "red":
      return "10-12 jaar";
    case "orange":
      return "12-18 jaar";
  }
}

export default function AgeDropdown() {
  const { push, query } = useRouter();
  const theme = useThemeStore(state => state.theme);
  const setTheme = useThemeStore(state => state.setTheme);

  const isAgeBasedPage = !!query.rootSlug;

  return (
    <Menu as="div" className="relative font-sans">
      <Menu.Button className="flex appearance-none items-center rounded bg-grey-200 px-3.5 py-2 text-sm font-semibold focus:outline-none focus:ring focus:ring-offset-2 focus-visible:ring focus-visible:ring-offset-2">
        <span>{getActiveAgeCategory(theme)}</span>
        <SvgChevron className="ml-2 w-2.5 text-darkblue" aria-hidden="true" />
      </Menu.Button>

      <Transition
        as={Fragment}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <Menu.Items className="absolute right-0 z-10 mt-3 w-[150px] space-y-1 rounded border border-grey-300 bg-white p-1 text-sm font-semibold focus:outline-none">
          <Menu.Item>
            {({ active }) => (
              <button
                className={classNames(
                  "block flex w-full items-center rounded px-3.5 py-3",
                  theme === "blue" ? "bg-grey-200" : "bg-white",
                  active && "ring",
                )}
                onClick={async () => {
                  setTheme("blue");
                  isAgeBasedPage && (await push("/4-7"));
                }}
              >
                <SvgGhostIcon className="mr-2.5 w-2.5 text-blue" aria-hidden="true" />
                4-7 jaar
              </button>
            )}
          </Menu.Item>
          <Menu.Item>
            {({ active }) => (
              <button
                className={classNames(
                  "block flex w-full items-center rounded px-3.5 py-3",
                  theme === "green" ? "bg-grey-200" : "bg-white",
                  active && "ring",
                )}
                onClick={async () => {
                  setTheme("green");
                  isAgeBasedPage && (await push("/7-10"));
                }}
              >
                <SvgGhostIcon className="mr-2.5 w-2.5 text-green" aria-hidden="true" />
                7-10 jaar
              </button>
            )}
          </Menu.Item>
          <Menu.Item>
            {({ active }) => (
              <button
                className={classNames(
                  "block flex w-full items-center rounded px-3.5 py-3",
                  theme === "red" ? "bg-grey-200" : "bg-white",
                  active && "ring",
                )}
                onClick={async () => {
                  setTheme("red");
                  isAgeBasedPage && (await push("/10-12"));
                }}
              >
                <SvgGhostIcon className="mr-2.5 w-2.5 text-red" aria-hidden="true" />
                10-12 jaar
              </button>
            )}
          </Menu.Item>
          <Menu.Item>
            {({ active }) => (
              <button
                className={classNames(
                  "block flex w-full items-center rounded px-3.5 py-3",
                  theme === "orange" ? "bg-grey-200" : "bg-white",
                  active && "ring",
                )}
                onClick={async () => {
                  setTheme("orange");
                  isAgeBasedPage && (await push("/12-18"));
                }}
              >
                <SvgGhostIcon className="mr-2.5 w-2.5 text-orange" aria-hidden="true" />
                12-18 jaar
              </button>
            )}
          </Menu.Item>
        </Menu.Items>
      </Transition>
    </Menu>
  );
}
