import type { PropsWithChildren } from "react";

import type { PageProps } from "lib/getPageProps";

import Footer from "components/Footer";
import Navigation from "components/Navigation";

export default function Layout({
  footer,
  navigation,
  children,
  hideAgeSelector,
  allPages,
}: PropsWithChildren<PageProps>) {
  return (
    <>
      <div className="bg-offWhite relative flex min-h-screen flex-col">
        <Navigation navigation={navigation} hideAgeSelector={hideAgeSelector} />
        <main className="relative flex flex-grow flex-col pt-[100px] md:pt-[160px]" id="main">
          {children}
        </main>
        <Footer footer={footer} allPages={allPages} />
      </div>
    </>
  );
}
