import * as React from "react";
import type { SVGProps } from "react";

function SvgQuestionmark(props: SVGProps<SVGSVGElement>) {
  return (
    <svg viewBox="0 0 32 32" fill="none" role="img" {...props}>
      <path
        d="M16 0C24.8368 0 32 7.1648 32 16C32 24.8352 24.8368 32 16 32C7.1632 32 0 24.8352 0 16C0 7.1648 7.1632 0 16 0ZM16 21.6C15.5757 21.6 15.1687 21.7686 14.8686 22.0686C14.5686 22.3687 14.4 22.7757 14.4 23.2C14.4 23.6243 14.5686 24.0313 14.8686 24.3314C15.1687 24.6314 15.5757 24.8 16 24.8C16.4243 24.8 16.8313 24.6314 17.1314 24.3314C17.4314 24.0313 17.6 23.6243 17.6 23.2C17.6 22.7757 17.4314 22.3687 17.1314 22.0686C16.8313 21.7686 16.4243 21.6 16 21.6ZM16 7.6C14.833 7.6 13.7139 8.06357 12.8887 8.88873C12.0636 9.71389 11.6 10.833 11.6 12C11.6001 12.304 11.7156 12.5967 11.9232 12.8189C12.1307 13.041 12.4149 13.1761 12.7182 13.1968C13.0215 13.2176 13.3214 13.1224 13.5573 12.9305C13.7931 12.7386 13.9474 12.4644 13.9888 12.1632L14 12C14 11.4696 14.2107 10.9609 14.5858 10.5858C14.9609 10.2107 15.4696 10 16 10C16.5304 10 17.0391 10.2107 17.4142 10.5858C17.7893 10.9609 18 11.4696 18 12C18 12.8624 17.784 13.288 16.968 14.1312L16.752 14.352C15.3472 15.7568 14.8 16.6672 14.8 18.4C14.8 18.7183 14.9264 19.0235 15.1515 19.2485C15.3765 19.4736 15.6817 19.6 16 19.6C16.3183 19.6 16.6235 19.4736 16.8485 19.2485C17.0736 19.0235 17.2 18.7183 17.2 18.4C17.2 17.5376 17.416 17.112 18.232 16.2688L18.448 16.048C19.8528 14.6432 20.4 13.7328 20.4 12C20.4 10.833 19.9364 9.71389 19.1113 8.88873C18.2861 8.06357 17.167 7.6 16 7.6Z"
        fill="currentColor"
      />
    </svg>
  );
}

export default SvgQuestionmark;
