import * as React from "react";
import type { SVGProps } from "react";

function SvgBook(props: SVGProps<SVGSVGElement>) {
  return (
    <svg viewBox="0 0 32 32" fill="none" role="img" {...props}>
      <path
        d="M16 2.16637C14.03 0.0979948 11.174 -0.209698 8.574 0.107763C5.546 0.481389 2.49 1.74879 0.586 2.80618C0.411298 2.90319 0.263151 3.05954 0.159242 3.25656C0.0553334 3.45358 5.31428e-05 3.68295 0 3.91729L0 30.7793C4.6324e-05 30.9836 0.0420743 31.1846 0.122234 31.364C0.202394 31.5433 0.318123 31.6952 0.458822 31.8057C0.59952 31.9163 0.760689 31.982 0.927567 31.9968C1.09444 32.0116 1.2617 31.975 1.414 31.8904C3.178 30.9136 6.02 29.739 8.774 29.3996C11.592 29.0528 13.954 29.6121 15.22 31.5412C15.3137 31.6838 15.4324 31.7989 15.5674 31.878C15.7024 31.9571 15.8503 31.9982 16 31.9982C16.1497 31.9982 16.2976 31.9571 16.4326 31.878C16.5676 31.7989 16.6863 31.6838 16.78 31.5412C18.046 29.6121 20.408 29.0528 23.224 29.3996C25.98 29.739 28.824 30.9136 30.586 31.8904C30.7383 31.975 30.9056 32.0116 31.0724 31.9968C31.2393 31.982 31.4005 31.9163 31.5412 31.8057C31.6819 31.6952 31.7976 31.5433 31.8778 31.364C31.9579 31.1846 32 30.9836 32 30.7793V3.91729C31.9999 3.68295 31.9447 3.45358 31.8408 3.25656C31.7368 3.05954 31.5887 2.90319 31.414 2.80618C29.51 1.74879 26.454 0.481389 23.426 0.107763C20.826 -0.21214 17.97 0.0979948 16 2.16637Z"
        fill="currentColor"
      />
    </svg>
  );
}

export default SvgBook;
