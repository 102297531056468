import * as React from "react";
import type { SVGProps } from "react";

function SvgStar(props: SVGProps<SVGSVGElement>) {
  return (
    <svg viewBox="0 0 32 32" fill="none" role="img" {...props}>
      <path
        d="M30.9657 11.2681L21.3727 9.80817L17.0844 0.704271C16.9673 0.455012 16.7746 0.25323 16.5366 0.130579C15.9396 -0.178028 15.2142 0.0791446 14.9157 0.704271L10.6274 9.80817L1.03443 11.2681C0.769949 11.3077 0.52814 11.4382 0.343006 11.6361C0.119189 11.877 -0.00414439 12.2011 0.000106342 12.5371C0.00435708 12.8732 0.135844 13.1938 0.365676 13.4284L7.30632 20.5144L5.66656 30.5204C5.62811 30.7532 5.6527 30.9926 5.73756 31.2114C5.82242 31.4303 5.96414 31.6199 6.14666 31.7587C6.32917 31.8975 6.54518 31.98 6.77019 31.9968C6.99519 32.0136 7.22019 31.964 7.41967 31.8538L16.0001 27.1297L24.5805 31.8538C24.8147 31.9843 25.0868 32.0278 25.3475 31.9804C26.0049 31.8617 26.4469 31.2088 26.3336 30.5204L24.6938 20.5144L31.6345 13.4284C31.8234 13.2345 31.9481 12.9813 31.9859 12.7043C32.0879 12.0119 31.6269 11.371 30.9657 11.2681V11.2681Z"
        fill="currentColor"
      />
    </svg>
  );
}

export default SvgStar;
