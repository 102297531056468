import * as React from "react";
import type { SVGProps } from "react";

function SvgHamburger(props: SVGProps<SVGSVGElement>) {
  return (
    <svg viewBox="0 0 16 16" fill="none" role="img" {...props}>
      <rect width={16} height={2} fill="currentColor" />
      <rect y={7} width={16} height={2} fill="currentColor" />
      <rect x={6} y={14} width={10} height={2} fill="currentColor" />
    </svg>
  );
}

export default SvgHamburger;
