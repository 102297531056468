import { useEffect, useRef, useState } from "react";

import Link from "next/link";
import { useRouter } from "next/router";

import classNames from "classnames";
import { FocusOn } from "react-focus-on";

import constructPageUrl from "lib/constructPageUrl";
import isExternal from "lib/isExternal";

import type { NavigationRecord } from "cms/types";

import { useThemeStore } from "hooks/useThemeStore";

import { SvgCross, SvgHamburger, SvgMagnifier, SvgTopbarLogo } from "assets/svg";

import AgeDropdown from "./AgeDropdown";
import Button from "./Button";

export default function Navigation({
  navigation,
  hideAgeSelector,
}: {
  navigation: NavigationRecord;
  hideAgeSelector?: boolean;
}) {
  const router = useRouter();
  const theme = useThemeStore(state => state.theme);
  const [menuOpen, setMenuOpen] = useState(false);

  const menuButtonRef = useRef<HTMLButtonElement>(null);

  const hideAgeSelectorByPaths = ["/"].includes(router.pathname);

  useEffect(() => {
    const mql = window.matchMedia("(min-width: 768px)");

    mql.onchange = e => {
      if (e.matches) {
        setMenuOpen(false);
      }
    };
  }, []);

  function isActivePage(path: string) {
    return router.asPath === path;
  }

  useEffect(() => {
    const handleComplete = () => {
      setMenuOpen(false);
    };

    router.events.on("routeChangeComplete", handleComplete);
    return () => {
      router.events.off("routeChangeComplete", handleComplete);
    };
  }, [router]);

  return (
    <nav className="fixed left-0 top-0 z-20 w-full font-heading">
      <div className="relative z-20 h-[50px] border-b border-grey-200 bg-white shadow-[0_4px_15px_rgba(0,0,0,0.04)] md:h-[80px]">
        <div className="mx-auto flex h-full max-w-7xl items-center justify-between px-4 sm:px-5">
          <a
            href="#main"
            className="group sr-only left-20 top-1/2 flex -translate-y-1/2 transform items-center justify-center outline-none focus:not-sr-only focus:absolute focus:z-20"
          >
            <Button variant="green">Ga naar hoofdinhoud</Button>
          </a>

          <div className="flex items-center space-x-6">
            <Link
              href="/"
              className="relative focus:rounded focus:outline-none focus:ring focus:ring-offset-2"
            >
              <SvgTopbarLogo
                className={classNames(
                  "w-[120px] md:w-[132px]",
                  `text-${
                    theme !== "initial" && !hideAgeSelectorByPaths && !hideAgeSelector ? theme : "green"
                  }`,
                )}
                role="img"
                aria-label="Logo Breinspoken"
              />
            </Link>

            {!hideAgeSelectorByPaths && !hideAgeSelector && <AgeDropdown />}
          </div>

          <div>
            <ul className="hidden space-x-5 text-darkblue md:flex md:items-center">
              {navigation.items.map(item => {
                if (item.__typename === "PageLinkRecord") {
                  return (
                    <li key={item.id}>
                      <Link
                        href={constructPageUrl(item)}
                        className={classNames(
                          "rounded px-4 py-2.5 focus:rounded focus:outline-none focus:ring focus:ring-offset-2",
                          isActivePage(constructPageUrl(item))
                            ? "bg-green/5 text-[#4c7f2f]"
                            : "bg-transparent hover:bg-darkblue/5 ",
                        )}
                      >
                        {item.page?.title}
                      </Link>
                    </li>
                  );
                }

                if (item.__typename === "CustomPageLinkRecord") {
                  return (
                    <li key={item.id}>
                      <Link
                        href={item.url ?? ""}
                        target={isExternal(item.url ?? "") ? "_blank" : undefined}
                        rel={isExternal(item.url ?? "") ? "noopener noreferrer" : undefined}
                        className={classNames(
                          "rounded px-4 py-2.5 focus:rounded focus:outline-none focus:ring focus:ring-offset-2",
                          isActivePage(item.url ?? "")
                            ? "bg-green/5 text-[#4c7f2f]"
                            : "bg-transparent hover:bg-darkblue/5 ",
                        )}
                      >
                        {item.title}
                      </Link>
                    </li>
                  );
                }
              })}

              <li className="flex items-center">
                <Link
                  href="/zoeken"
                  className={classNames(
                    "rounded px-4 py-2.5 focus:rounded focus:outline-none focus:ring focus:ring-offset-2",
                    isActivePage("/zoeken")
                      ? "bg-green/5 text-[#4c7f2f]"
                      : "bg-transparent hover:bg-darkblue/5 ",
                  )}
                >
                  <SvgMagnifier className="w-4" aria-hidden="true" />
                  <span className="sr-only">Ga naar de zoekpagina</span>
                </Link>
              </li>
            </ul>

            <button
              type="button"
              ref={menuButtonRef}
              onClick={() => setMenuOpen(prev => !prev)}
              className="flex h-10 w-10 appearance-none items-center justify-center outline-none focus:rounded focus:ring focus:ring-offset-2 focus-visible:rounded focus-visible:ring focus-visible:ring-offset-2 md:hidden"
              aria-label={menuOpen ? "Menu sluiten" : "Menu openen"}
            >
              {menuOpen ? (
                <SvgCross className="h-4 text-darkblue" aria-hidden="true" />
              ) : (
                <SvgHamburger className="h-4 text-darkblue" aria-hidden="true" />
              )}
            </button>
          </div>
        </div>
      </div>

      <FocusOn
        enabled={menuOpen}
        onClickOutside={() => {
          setMenuOpen(false);
        }}
        onEscapeKey={() => {
          setMenuOpen(false);
        }}
        shards={[menuButtonRef]}
        className={menuOpen ? "fixed top-[50px] z-10 h-full w-full bg-white md:top-[80px]" : "hidden"}
      >
        <ul className="divide-y divide-grey-200 text-darkblue">
          {navigation.items.map(item => {
            if (item.__typename === "PageLinkRecord") {
              return (
                <li key={item.id}>
                  <Link
                    href={constructPageUrl(item)}
                    className={classNames(
                      "block p-5",
                      isActivePage(constructPageUrl(item)) && "font-bold text-[#4c7f2f]",
                    )}
                  >
                    {item.page?.title}
                  </Link>
                </li>
              );
            }

            if (item.__typename === "CustomPageLinkRecord") {
              return (
                <li key={item.id}>
                  <Link
                    href={item.url ?? ""}
                    target={isExternal(item.url ?? "") ? "_blank" : undefined}
                    rel={isExternal(item.url ?? "") ? "noopener noreferrer" : undefined}
                    className={classNames(
                      "block p-5",
                      isActivePage(item.url ?? "") && "font-bold text-[#4c7f2f]",
                    )}
                  >
                    {item.title}
                  </Link>
                </li>
              );
            }
          })}

          <li>
            <Link
              href="/zoeken"
              className={classNames(
                "flex items-center space-x-2 p-5",
                isActivePage("/zoeken") && "font-bold text-[#4c7f2f]",
              )}
            >
              <SvgMagnifier className="w-4" aria-hidden="true" />
              <span>Zoeken</span>
            </Link>
          </li>
        </ul>
      </FocusOn>
    </nav>
  );
}
