import * as React from "react";
import type { SVGProps } from "react";

function SvgHeart(props: SVGProps<SVGSVGElement>) {
  return (
    <svg viewBox="0 0 23 20" fill="none" role="img" {...props}>
      <path
        d="M21.785 4.0986C21.4386 3.29655 20.9392 2.56975 20.3146 1.95886C19.6896 1.34616 18.9526 0.85925 18.1439 0.524616C17.3052 0.176245 16.4057 -0.002072 15.4976 1.81642e-05C14.2236 1.81642e-05 12.9806 0.348889 11.9004 1.00787C11.6419 1.1655 11.3964 1.33865 11.1639 1.5273C10.9313 1.33865 10.6858 1.1655 10.4274 1.00787C9.34715 0.348889 8.10414 1.81642e-05 6.83011 1.81642e-05C5.91271 1.81642e-05 5.02374 0.175746 4.18386 0.524616C3.37242 0.860566 2.64108 1.34382 2.01311 1.95886C1.38772 2.56906 0.888142 3.29604 0.542688 4.0986C0.18348 4.93331 0 5.8197 0 6.73193C0 7.59248 0.175728 8.4892 0.524598 9.40144C0.816616 10.1638 1.23526 10.9546 1.7702 11.7531C2.61782 13.0168 3.78331 14.3347 5.23048 15.6708C7.62864 17.8855 10.0035 19.4153 10.1043 19.4773L10.7168 19.8701C10.9881 20.0433 11.337 20.0433 11.6083 19.8701L12.2208 19.4773C12.3216 19.4127 14.6939 17.8855 17.0947 15.6708C18.5418 14.3347 19.7073 13.0168 20.5549 11.7531C21.0899 10.9546 21.5111 10.1638 21.8005 9.40144C22.1494 8.4892 22.3251 7.59248 22.3251 6.73193C22.3277 5.8197 22.1442 4.93331 21.785 4.0986Z"
        fill="currentColor"
      />
    </svg>
  );
}

export default SvgHeart;
