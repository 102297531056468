import create from "zustand";

export type ThemeType = "initial" | "blue" | "green" | "red" | "orange";

export type ThemeStoreState = {
  theme: ThemeType;
  parentalMode: boolean;
  setTheme: (theme: ThemeType) => void;
  toggleParentalMode: () => void;
};

export const useThemeStore = create<ThemeStoreState>(set => ({
  theme: "initial",
  parentalMode: false,
  setTheme: theme => set(() => ({ theme: theme })),
  toggleParentalMode: () => set(state => ({ parentalMode: !state.parentalMode })),
}));
