import * as React from "react";
import type { SVGProps } from "react";

function SvgInfo(props: SVGProps<SVGSVGElement>) {
  return (
    <svg viewBox="0 0 32 32" fill="none" role="img" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16 32C24.8366 32 32 24.8366 32 16C32 7.16344 24.8366 0 16 0C7.16344 0 0 7.16344 0 16C0 24.8366 7.16344 32 16 32ZM14.4761 14.4762H17.5237V22.8571H14.4761V14.4762ZM17.9047 10.2857C17.9047 11.3377 17.0519 12.1905 16 12.1905C14.948 12.1905 14.0952 11.3377 14.0952 10.2857C14.0952 9.23374 14.948 8.38095 16 8.38095C17.0519 8.38095 17.9047 9.23374 17.9047 10.2857Z"
        fill="currentColor"
      />
    </svg>
  );
}

export default SvgInfo;
