import * as React from "react";
import type { SVGProps } from "react";

function SvgPencil(props: SVGProps<SVGSVGElement>) {
  return (
    <svg viewBox="0 0 32 32" fill="none" role="img" {...props}>
      <path
        d="M4.57143 0C2.05 0 0 2.05 0 4.57143V27.4286C0 29.95 2.05 32 4.57143 32H27.4286C29.95 32 32 29.95 32 27.4286V4.57143C32 2.05 29.95 0 27.4286 0H4.57143ZM23.2714 7.69286L24.3 8.72143C25.4143 9.83572 25.4143 11.6429 24.3 12.7643L22.7714 14.2929L17.7 9.22143L19.2286 7.69286C20.3429 6.57857 22.15 6.57857 23.2714 7.69286ZM8.56429 18.3571L16.0786 10.8429L21.15 15.9143L13.6357 23.4214C13.3429 23.7143 12.9786 23.9214 12.5714 24.0214L8.27857 25.0929C7.88571 25.1929 7.47857 25.0786 7.19286 24.7929C6.90714 24.5071 6.79286 24.1 6.89286 23.7071L7.96429 19.4143C8.06429 19.0143 8.27143 18.6429 8.56429 18.35V18.3571Z"
        fill="currentColor"
      />
    </svg>
  );
}

export default SvgPencil;
