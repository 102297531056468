import * as React from "react";
import type { SVGProps } from "react";

function SvgShield(props: SVGProps<SVGSVGElement>) {
  return (
    <svg viewBox="0 0 28 32" fill="none" role="img" {...props}>
      <path
        d="M8.14401 1.12C10.314 0.53 12.62 0 14 0C15.38 0 17.686 0.53 19.856 1.12C22.076 1.72 24.314 2.43 25.63 2.86C26.1802 3.0417 26.668 3.37493 27.0374 3.8214C27.4067 4.26787 27.6426 4.80948 27.718 5.384C28.91 14.338 26.144 20.974 22.788 25.364C21.3649 27.242 19.668 28.8957 17.754 30.27C17.0922 30.7456 16.391 31.1637 15.658 31.52C15.098 31.784 14.496 32 14 32C13.504 32 12.904 31.784 12.342 31.52C11.609 31.1638 10.9078 30.7456 10.246 30.27C8.33207 28.8957 6.63517 27.2419 5.21201 25.364C1.85601 20.974 -0.909989 14.338 0.282011 5.384C0.357385 4.80948 0.593297 4.26787 0.962645 3.8214C1.33199 3.37493 1.81979 3.0417 2.37001 2.86C4.28039 2.23362 6.20562 1.65345 8.14401 1.12V1.12Z"
        fill="currentColor"
      />
    </svg>
  );
}

export default SvgShield;
