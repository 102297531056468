import * as React from "react";
import type { SVGProps } from "react";

function SvgCross(props: SVGProps<SVGSVGElement>) {
  return (
    <svg viewBox="0 0 8 8" fill="none" role="img" {...props}>
      <rect
        x={0.88916}
        width={10.0566}
        height={1.25707}
        transform="rotate(45 0.88916 0)"
        fill="currentColor"
      />
      <rect
        x={0.000488281}
        y={7.11084}
        width={10.0566}
        height={1.25707}
        transform="rotate(-45 0.000488281 7.11084)"
        fill="currentColor"
      />
    </svg>
  );
}

export default SvgCross;
