import * as React from "react";
import type { SVGProps } from "react";

function SvgEmailSent(props: SVGProps<SVGSVGElement>) {
  return (
    <svg viewBox="0 0 48 48" role="img" {...props}>
      <path
        d="m31.65 44-8.05-8.05 2.15-2.15 5.9 5.9 12.2-12.2L46 29.65ZM23.9 21.65 40.6 11H7.2Zm0 3L7 13.8v22.6h12.8l3 3H7q-1.2 0-2.1-.9-.9-.9-.9-2.1V11q0-1.2.9-2.1Q5.8 8 7 8h33.85q1.2 0 2.1.9.9.9.9 2.1v12.2l-3 3V13.8Zm.05.45Zm-.05-3.45Zm.05 3Z"
        fill="currentColor"
      />
    </svg>
  );
}

export default SvgEmailSent;
