import * as React from "react";
import type { SVGProps } from "react";

function SvgClock(props: SVGProps<SVGSVGElement>) {
  return (
    <svg viewBox="0 0 32 32" fill="none" role="img" {...props}>
      <path
        d="M16 0C7.1776 0 0 7.1776 0 16C0 24.8224 7.1776 32 16 32C24.8224 32 32 24.8224 32 16C32 7.1776 24.8224 0 16 0ZM25.2 17.6H14.4V6.4H17.6V14.4H25.2V17.6Z"
        fill="currentColor"
      />
    </svg>
  );
}

export default SvgClock;
