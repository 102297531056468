import Head from "next/head";

import { renderMetaTags } from "react-datocms";

import type { Query } from "cms/types";
import type { Tag } from "cms/types";

export default function PageMeta({
  siteMeta,
  pageMeta,
  title,
  description,
}: {
  siteMeta: Query["_site"];
  pageMeta?: Array<Tag>;
  title?: string;
  description?: string;
}) {
  if (pageMeta) {
    return <Head>{renderMetaTags(pageMeta)}</Head>;
  }

  const pageTitle = title
    ? `${title} - ${siteMeta.globalSeo?.fallbackSeo?.title}`
    : siteMeta.globalSeo?.fallbackSeo?.title;

  const pageDescription = description ? description : siteMeta.globalSeo?.fallbackSeo?.description;

  return (
    <Head>
      <title>{pageTitle}</title>
      <meta name="description" content={pageDescription ?? ""} />
      <meta property="og:title" content={siteMeta.globalSeo?.fallbackSeo?.title ?? ""} />
      <meta name="og:description" content={pageDescription ?? ""} />
      <meta property="og:locale" content="nl" />
      <meta property="og:image" content={siteMeta.globalSeo?.fallbackSeo?.image?.responsiveImage?.src} />
      <meta property="og:image:alt" content={siteMeta.globalSeo?.fallbackSeo?.image?.alt ?? ""} />
      <meta property="og:site_name" content={siteMeta.globalSeo?.siteName ?? ""} />
      <meta property="twitter:title" content={siteMeta.globalSeo?.fallbackSeo?.title ?? ""} />
      <meta name="twitter:description" content={pageDescription ?? ""} />
      <meta property="twitter:image" content={siteMeta.globalSeo?.fallbackSeo?.image?.responsiveImage?.src} />
      <meta property="twitter:image:alt" content={siteMeta.globalSeo?.fallbackSeo?.image?.alt ?? ""} />
      <meta property="twitter:card" content={siteMeta.globalSeo?.fallbackSeo?.twitterCard ?? ""} />
    </Head>
  );
}
