import type { SVGProps } from "react";
import * as React from "react";
import { useEffect, useMemo, useState } from "react";

function SvgBlob({ opacity, ...props }: { opacity: number } & SVGProps<SVGSVGElement>) {
  const [value, setValue] = useState<string>();

  const path = useMemo(() => {
    const paths = [
      "M46.2,-44.5C62.9,-29.6,81.3,-14.8,77.9,-3.5C74.4,7.8,48.9,15.7,32.3,21.3C15.7,26.9,7.8,30.3,-6.7,37C-21.2,43.7,-42.4,53.6,-52.4,48C-62.5,42.4,-61.3,21.2,-56.8,4.5C-52.3,-12.2,-44.4,-24.3,-34.4,-39.3C-24.3,-54.2,-12.2,-72,1.3,-73.3C14.8,-74.6,29.6,-59.5,46.2,-44.5Z;M27.4,-32.6C36,-18.7,43.9,-9.4,50.2,6.3C56.5,22,61.2,44,52.6,50.2C44,56.4,22,46.9,1.2,45.6C-19.5,44.4,-39.1,51.5,-53.3,45.3C-67.5,39.1,-76.4,19.5,-76.2,0.2C-76.1,-19.2,-66.9,-38.5,-52.7,-52.4C-38.5,-66.3,-19.2,-74.8,-4.9,-69.9C9.4,-64.9,18.7,-46.5,27.4,-32.6Z;M46.2,-44.5C62.9,-29.6,81.3,-14.8,77.9,-3.5C74.4,7.8,48.9,15.7,32.3,21.3C15.7,26.9,7.8,30.3,-6.7,37C-21.2,43.7,-42.4,53.6,-52.4,48C-62.5,42.4,-61.3,21.2,-56.8,4.5C-52.3,-12.2,-44.4,-24.3,-34.4,-39.3C-24.3,-54.2,-12.2,-72,1.3,-73.3C14.8,-74.6,29.6,-59.5,46.2,-44.5Z",
      "M30.9,-47C41.5,-34.9,52.3,-27.7,62.2,-15.9C72,-4,80.8,12.6,75.3,22.9C69.8,33.3,50.1,37.5,35.1,45.9C20.2,54.2,10.1,66.7,0.1,66.6C-9.9,66.5,-19.9,53.7,-33.9,45.1C-47.9,36.5,-66.1,31.9,-76.4,20.1C-86.8,8.3,-89.4,-10.8,-84.5,-27.8C-79.5,-44.9,-66.9,-59.8,-51.5,-70.3C-36.2,-80.8,-18.1,-87,-4,-81.5C10.2,-76.1,20.4,-59.1,30.9,-47Z;M39,-49.2C53.5,-43.2,70.1,-35.7,79.6,-22.1C89.1,-8.6,91.5,11,84.4,25.7C77.3,40.5,60.5,50.4,44.9,61.4C29.2,72.4,14.6,84.4,-1.2,86.1C-17,87.7,-34,79,-44.1,66.2C-54.2,53.4,-57.4,36.5,-63.3,20.1C-69.3,3.7,-78,-12.2,-74.3,-24.2C-70.5,-36.2,-54.2,-44.2,-39.8,-50.2C-25.3,-56.2,-12.6,-60.3,-0.2,-60C12.3,-59.8,24.6,-55.2,39,-49.2Z;M30.9,-47C41.5,-34.9,52.3,-27.7,62.2,-15.9C72,-4,80.8,12.6,75.3,22.9C69.8,33.3,50.1,37.5,35.1,45.9C20.2,54.2,10.1,66.7,0.1,66.6C-9.9,66.5,-19.9,53.7,-33.9,45.1C-47.9,36.5,-66.1,31.9,-76.4,20.1C-86.8,8.3,-89.4,-10.8,-84.5,-27.8C-79.5,-44.9,-66.9,-59.8,-51.5,-70.3C-36.2,-80.8,-18.1,-87,-4,-81.5C10.2,-76.1,20.4,-59.1,30.9,-47Z",
      "M27.1,-37.6C37.4,-29.8,49.7,-25,58.5,-15C67.3,-4.9,72.8,10.2,67.3,20.3C61.9,30.4,45.6,35.3,32.6,40.1C19.5,44.9,9.8,49.4,-3.3,54C-16.4,58.6,-32.8,63.1,-43.8,57.7C-54.7,52.3,-60.2,36.8,-63.1,21.9C-66,7.1,-66.2,-7.3,-59.1,-16.1C-52,-25,-37.5,-28.4,-26.5,-36C-15.4,-43.5,-7.7,-55.3,0.4,-55.8C8.4,-56.2,16.8,-45.5,27.1,-37.6Z;M46.3,-56C60.3,-53.6,72.1,-40.4,72.2,-26.7C72.3,-12.9,60.7,1.5,53.2,15.1C45.8,28.6,42.5,41.4,34.4,54.3C26.2,67.3,13.1,80.5,0.7,79.5C-11.7,78.5,-23.3,63.3,-37.9,52.5C-52.5,41.6,-70.1,35,-74.3,23.7C-78.6,12.5,-69.5,-3.6,-62.7,-19.1C-55.9,-34.6,-51.4,-49.5,-41.3,-53.2C-31.2,-56.9,-15.6,-49.3,0.3,-49.6C16.2,-50,32.3,-58.3,46.3,-56Z;M27.1,-37.6C37.4,-29.8,49.7,-25,58.5,-15C67.3,-4.9,72.8,10.2,67.3,20.3C61.9,30.4,45.6,35.3,32.6,40.1C19.5,44.9,9.8,49.4,-3.3,54C-16.4,58.6,-32.8,63.1,-43.8,57.7C-54.7,52.3,-60.2,36.8,-63.1,21.9C-66,7.1,-66.2,-7.3,-59.1,-16.1C-52,-25,-37.5,-28.4,-26.5,-36C-15.4,-43.5,-7.7,-55.3,0.4,-55.8C8.4,-56.2,16.8,-45.5,27.1,-37.6Z",
      "M46.9,-47.6C61.9,-32,75.9,-16,78.2,2.3C80.4,20.5,71,41.1,56,52.6C41.1,64,20.5,66.5,1.1,65.4C-18.4,64.4,-36.8,59.8,-46,48.3C-55.3,36.8,-55.4,18.4,-56,-0.6C-56.6,-19.6,-57.6,-39.2,-48.4,-54.8C-39.2,-70.4,-19.6,-82,-1.8,-80.2C16,-78.4,32,-63.2,46.9,-47.6Z;M61.2,-55.8C77.8,-44.6,88.6,-22.3,88.4,-0.2C88.2,21.9,77,43.8,60.4,56C43.8,68.1,21.9,70.5,-0.4,70.9C-22.7,71.2,-45.3,69.6,-56.3,57.5C-67.4,45.3,-66.8,22.7,-65.9,0.9C-65,-20.9,-63.8,-41.8,-52.8,-53C-41.8,-64.2,-20.9,-65.7,0.7,-66.4C22.3,-67.1,44.6,-67,61.2,-55.8Z;M46.9,-47.6C61.9,-32,75.9,-16,78.2,2.3C80.4,20.5,71,41.1,56,52.6C41.1,64,20.5,66.5,1.1,65.4C-18.4,64.4,-36.8,59.8,-46,48.3C-55.3,36.8,-55.4,18.4,-56,-0.6C-56.6,-19.6,-57.6,-39.2,-48.4,-54.8C-39.2,-70.4,-19.6,-82,-1.8,-80.2C16,-78.4,32,-63.2,46.9,-47.6Z",
      "M17.4,-16.4C31.9,-2.9,59.4,-1.5,69.6,10.2C79.8,21.8,72.6,43.7,58.1,58.9C43.7,74.1,21.8,82.7,9.2,73.5C-3.5,64.4,-7,37.4,-8.6,22.2C-10.2,7,-10,3.5,-13.1,-3.1C-16.2,-9.7,-22.7,-19.5,-21.1,-32.9C-19.5,-46.3,-9.7,-63.4,-4.1,-59.3C1.5,-55.2,2.9,-29.8,17.4,-16.4Z;M31.2,-37.8C37.1,-25.2,36.4,-12.6,39,2.6C41.5,17.8,47.4,35.5,41.4,37.1C35.5,38.8,17.8,24.3,-0.8,25.1C-19.4,26,-38.8,42.1,-47.8,40.5C-56.7,38.8,-55.2,19.4,-46.5,8.7C-37.7,-2,-21.8,-3.9,-12.8,-16.5C-3.9,-29,-2,-52.2,5.3,-57.5C12.6,-62.8,25.2,-50.3,31.2,-37.8Z;M17.4,-16.4C31.9,-2.9,59.4,-1.5,69.6,10.2C79.8,21.8,72.6,43.7,58.1,58.9C43.7,74.1,21.8,82.7,9.2,73.5C-3.5,64.4,-7,37.4,-8.6,22.2C-10.2,7,-10,3.5,-13.1,-3.1C-16.2,-9.7,-22.7,-19.5,-21.1,-32.9C-19.5,-46.3,-9.7,-63.4,-4.1,-59.3C1.5,-55.2,2.9,-29.8,17.4,-16.4Z",
      "M22.6,-27.6C32.7,-18.3,46.9,-14.6,57.3,-3.1C67.7,8.4,74.3,27.6,68.1,40.2C61.9,52.9,42.8,59.1,24,66C5.1,72.9,-13.5,80.7,-26.3,74.9C-39,69.1,-45.8,49.7,-50.5,33.2C-55.2,16.7,-57.8,3,-54.3,-8.3C-50.8,-19.6,-41.1,-28.5,-31,-37.8C-21,-47,-10.5,-56.5,-2.1,-53.9C6.2,-51.4,12.4,-36.8,22.6,-27.6Z;M28.6,-33.9C44.9,-20.4,71.3,-18.8,79.7,-8.5C88.1,1.7,78.4,20.7,65.7,33.5C53,46.2,37.3,52.7,23.6,52.1C10,51.5,-1.6,43.8,-12.9,38.4C-24.2,33,-35.3,29.9,-46.5,21.5C-57.7,13.1,-69,-0.5,-71.7,-17.4C-74.3,-34.2,-68.3,-54.2,-54.9,-68.3C-41.5,-82.3,-20.8,-90.3,-7.3,-81.6C6.1,-72.8,12.3,-47.4,28.6,-33.9Z;M22.6,-27.6C32.7,-18.3,46.9,-14.6,57.3,-3.1C67.7,8.4,74.3,27.6,68.1,40.2C61.9,52.9,42.8,59.1,24,66C5.1,72.9,-13.5,80.7,-26.3,74.9C-39,69.1,-45.8,49.7,-50.5,33.2C-55.2,16.7,-57.8,3,-54.3,-8.3C-50.8,-19.6,-41.1,-28.5,-31,-37.8C-21,-47,-10.5,-56.5,-2.1,-53.9C6.2,-51.4,12.4,-36.8,22.6,-27.6Z",
    ];

    return paths[Math.floor(Math.random() * paths.length)];
  }, []);

  useEffect(() => {
    setValue(path);
  }, [path]);

  return (
    <svg viewBox="0 0 200 200" style={{ opacity: opacity }} role="img" {...props}>
      <path fill="currentColor" style={{ opacity: opacity }} transform="translate(100 100)">
        <animate attributeName="d" dur="30s" repeatCount="indefinite" values={value}></animate>
      </path>
    </svg>
  );
}

export default SvgBlob;
