import * as React from "react";
import type { SVGProps } from "react";

function SvgChevron(props: SVGProps<SVGSVGElement>) {
  return (
    <svg viewBox="0 0 10 6" fill="none" role="img" {...props}>
      <path d="M2 1.5L5 4.5L8 1.5" stroke="currentColor" strokeWidth={2} strokeLinecap="square" />
    </svg>
  );
}

export default SvgChevron;
