import * as React from "react";
import type { SVGProps } from "react";
function SvgLightbase(props: SVGProps<SVGSVGElement>) {
  return (
    <svg viewBox="0 0 123 22" fill="none" {...props}>
      <g clipPath="url(#clip0_4671_1365)">
        <path d="M31.1907 2.95361V15.3526H38.1925V17.5407H28.7734V2.95361H31.1907Z" fill="#EFEBDF" />
        <path
          d="M40.8989 2.55762C41.8575 2.55762 42.5868 3.22446 42.5868 4.20388C42.5868 5.1833 41.8575 5.8293 40.8989 5.8293C39.9403 5.8293 39.2109 5.16246 39.2109 4.20388C39.2109 3.24529 39.9403 2.55762 40.8989 2.55762ZM42.045 17.5407H39.7736V7.26717H42.045V17.5407Z"
          fill="#EFEBDF"
        />
        <path
          d="M54.7372 7.26684V17.1652C54.7372 19.9993 52.7366 21.9998 49.5275 21.9998C46.7351 21.9998 45.1722 20.7078 44.1302 19.1032L46.0891 17.9154C46.6517 19.0199 47.7145 20.0201 49.3816 20.0201C51.1529 20.0201 52.4657 19.0616 52.4657 17.061V15.6857H52.2157C51.653 16.6026 50.6944 17.5403 48.7564 17.5403C46.1308 17.5403 43.9844 15.3523 43.9844 12.2473C43.9844 9.14232 46.1308 6.9751 48.7564 6.9751C50.6944 6.9751 51.653 7.892 52.2157 8.8089H52.5074V7.26684H54.7372ZM52.4648 12.2473C52.4648 10.2259 51.1519 9.01729 49.3598 9.01729C47.5677 9.01729 46.2757 10.1843 46.2757 12.2473C46.2757 14.3103 47.6094 15.4981 49.3598 15.4981C51.1103 15.4981 52.4648 14.2895 52.4648 12.2473Z"
          fill="#EFEBDF"
        />
        <path
          d="M59.5917 2.95361V8.976H59.8418C60.3002 7.99658 61.2797 7.07968 63.2177 7.07968C65.4891 7.07968 67.2395 8.66342 67.2395 11.3725V17.5407H64.9681V11.5808C64.9681 9.97626 64.0304 9.10103 62.4466 9.10103C60.6545 9.10103 59.5917 10.2055 59.5917 12.2685V17.5407H57.3203V2.95361H59.5917Z"
          fill="#EFEBDF"
        />
        <path
          d="M71.1335 7.26714V3.91211H73.405V7.26714H76.4266V9.1843H73.405V14.9775C73.405 15.3942 73.6133 15.6026 73.9884 15.6026H75.8431V17.5406H73.009C71.9254 17.5406 71.1335 16.7696 71.1335 15.6651V9.1843H68.3203V7.26714H71.1335Z"
          fill="#EFEBDF"
        />
        <path
          d="M80.6152 2.95361V8.80929H80.8652C81.4279 7.89239 82.3865 6.97549 84.3245 6.97549C86.9501 6.97549 89.0965 9.22607 89.0965 12.3936C89.0965 15.561 86.9501 17.8325 84.3245 17.8325C82.3865 17.8325 81.4279 16.9156 80.8652 15.9778H80.5735V17.5407H78.3438V2.95361H80.6152ZM80.6152 12.3936C80.6152 14.4983 81.928 15.7903 83.7201 15.7903C85.5123 15.7903 86.8043 14.5399 86.8043 12.3936C86.8043 10.2472 85.4706 9.01768 83.7201 9.01768C81.9697 9.01768 80.6152 10.2888 80.6152 12.3936Z"
          fill="#EFEBDF"
        />
        <path
          d="M95.6238 6.95459C98.3536 6.95459 100.187 8.55917 100.187 11.039V17.5407H97.9369V15.9986H97.6451C97.2075 16.8113 96.3739 17.8324 94.2067 17.8324C91.8936 17.8324 90.3516 16.6029 90.3516 14.6649C90.3516 12.8936 91.7686 11.8517 93.7691 11.6641L97.8952 11.2265V10.8723C97.8952 9.74698 97.0825 8.83007 95.5196 8.83007C93.9567 8.83007 93.0398 9.74698 92.4771 10.9556L90.6016 9.85117C91.3935 8.58001 92.873 6.95459 95.6238 6.95459ZM94.7277 15.9152C96.6865 15.9152 97.8952 14.7691 97.8952 13.0812V12.8936L94.3943 13.2687C93.3107 13.3938 92.7272 13.8522 92.7272 14.5816C92.7272 15.3734 93.4565 15.9152 94.7277 15.9152Z"
          fill="#EFEBDF"
        />
        <path
          d="M106.23 6.9751C108.585 6.9751 110.273 8.03787 111.44 9.35071L109.877 10.7052C108.731 9.28819 107.46 8.82974 106.335 8.82974C105.209 8.82974 104.563 9.2882 104.563 9.99671C104.563 10.7052 105.209 11.0178 106.293 11.2054L107.543 11.4137C109.752 11.7888 111.482 12.5182 111.482 14.5187C111.482 16.5192 109.669 17.8321 107.21 17.8321C104.751 17.8321 102.667 16.5817 101.625 14.7896L103.459 13.706C104.376 15.4565 105.834 15.9566 107.168 15.9566C108.502 15.9566 109.252 15.4148 109.252 14.6646C109.252 13.9144 108.585 13.6018 107.418 13.3934L106.168 13.185C103.813 12.8099 102.334 11.9347 102.334 10.0592C102.334 8.18374 103.917 6.9751 106.23 6.9751Z"
          fill="#EFEBDF"
        />
        <path
          d="M117.981 6.95459C120.94 6.95459 122.961 8.99678 122.961 12.1018V13.0603H115.188C115.292 14.6858 116.397 15.8527 118.085 15.8527C119.648 15.8527 120.46 14.9983 120.856 14.1439L122.753 15.2067C122.065 16.3737 120.856 17.8324 118.106 17.8324C115.001 17.8324 112.812 15.4985 112.812 12.3101C112.812 9.12182 114.959 6.95459 117.981 6.95459ZM120.606 11.3932C120.481 9.87201 119.481 8.93427 117.96 8.93427C116.334 8.93427 115.376 9.91369 115.188 11.3932H120.606Z"
          fill="#EFEBDF"
        />
        <path
          d="M10.6562 10.6562C13.5992 10.6562 15.9842 8.27108 15.9842 5.32808C15.9842 2.38509 13.5992 0 10.6562 0H5.32808L0 10.6562H5.32808L0 21.3123H10.6562C13.5992 21.3123 15.9842 18.9272 15.9842 15.9842C15.9842 13.0412 13.5992 10.6562 10.6562 10.6562Z"
          fill="#EFEBDF"
        />
      </g>
      <defs>
        <clipPath id="clip0_4671_1365">
          <rect width={122.962} height={22} fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}
export default SvgLightbase;
