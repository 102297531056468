import { Fragment } from "react";

import Link from "next/link";

import { Menu, Transition } from "@headlessui/react";
import classNames from "classnames";
import _ from "lodash";

import constructPageUrl from "lib/constructPageUrl";
import isExternal from "lib/isExternal";

import type { FooterRecord, Query } from "cms/types";

import { SvgAlzheimercentrum, SvgChevron, SvgLightbase } from "assets/svg";

export default function Footer({ footer, allPages }: { footer: FooterRecord; allPages: Query["allPages"] }) {
  const groupedByAge = _.chain(allPages)
    .groupBy(object => object.ageCategory?.title)
    .map((value, key) => ({ age: key, pages: value }))
    .value()
    .sort((a, b) => {
      if (parseInt(a.age) < parseInt(b.age)) {
        return -1;
      }
      if (parseInt(a.age) > parseInt(b.age)) {
        return 1;
      }
      return 0;
    });

  return (
    <footer className="relative mt-16 bg-darkblue py-8 md:mt-28">
      <div className="mx-auto h-full max-w-7xl px-4 sm:px-5">
        <a
          href="https://www.alzheimercentrum.nl/"
          target="_blank"
          rel="noopener noreferrer"
          className="absolute -top-8 left-1/2 flex h-16 w-[280px] -translate-x-1/2 transform items-center justify-center rounded-full bg-white px-4 shadow-[0_2px_15px_rgba(0,0,0,0.1)] outline-none focus:ring"
        >
          <SvgAlzheimercentrum role="img" aria-label="Logo Alzheimercentrum Amsterdam" className="w-full" />

          <span className="sr-only">Ga naar de website van Alzheimercentrum Amsterdam</span>
        </a>

        <div className="mb-16 mt-2.5 text-center text-base text-white md:mb-32">
          <span className="leading-relaxed">is de initiatiefnemer van </span>
          <span className="font-heading font-extrabold leading-relaxed">Breinspoken</span>
        </div>

        <div className="grid grid-cols-12 gap-x-5 gap-y-12">
          <div className="col-span-6 md:col-span-4 md:grid md:grid-cols-2 md:gap-x-5">
            <div className="col-span-6 mb-16 space-y-3 md:col-span-1">
              <span className="block px-3.5 text-sm font-medium text-white/[0.51]">Breinspoken</span>

              <ul>
                <li>
                  <Link
                    href="/"
                    className="flex w-full appearance-none items-center justify-between rounded border border-transparent px-3.5 py-3 text-left text-sm font-semibold text-white outline-none hover:bg-white/10 focus:border-white"
                  >
                    Home
                  </Link>
                </li>
                {footer.columnOne.map(item => {
                  if (item.__typename === "PageLinkRecord") {
                    return (
                      <li key={item.id}>
                        <Link
                          href={constructPageUrl(item)}
                          className="flex w-full appearance-none items-center justify-between rounded border border-transparent px-3.5 py-3 text-left text-sm font-semibold text-white outline-none hover:bg-white/10 focus:border-white"
                        >
                          {item.page?.title}
                        </Link>
                      </li>
                    );
                  }

                  if (item.__typename === "CustomPageLinkRecord") {
                    return (
                      <li key={item.id}>
                        <Link
                          href={item.url ?? ""}
                          target={isExternal(item.url ?? "") ? "_blank" : undefined}
                          rel={isExternal(item.url ?? "") ? "noopener noreferrer" : undefined}
                          className="flex w-full appearance-none items-center justify-between rounded border border-transparent px-3.5 py-3 text-left text-sm font-semibold text-white outline-none hover:bg-white/10 focus:border-white"
                        >
                          {item.title}
                        </Link>
                      </li>
                    );
                  }
                })}
              </ul>
            </div>

            <div className="col-span-6 space-y-3 md:order-2 md:col-span-1">
              <span className="block px-3.5 text-sm font-medium text-white/[0.51]">Steun ons</span>

              <ul>
                {footer.columnTwo.map(item => {
                  if (item.__typename === "PageLinkRecord") {
                    return (
                      <li key={item.id}>
                        <Link
                          href={constructPageUrl(item)}
                          className="flex w-full appearance-none items-center justify-between rounded border border-transparent px-3.5 py-3 text-left text-sm font-semibold text-white outline-none hover:bg-white/10 focus:border-white"
                        >
                          {item.page?.title}
                        </Link>
                      </li>
                    );
                  }

                  if (item.__typename === "CustomPageLinkRecord") {
                    return (
                      <li key={item.id}>
                        <Link
                          href={item.url ?? ""}
                          target={isExternal(item.url ?? "") ? "_blank" : undefined}
                          rel={isExternal(item.url ?? "") ? "noopener noreferrer" : undefined}
                          className="flex w-full appearance-none items-center justify-between rounded border border-transparent px-3.5 py-3 text-left text-sm font-semibold text-white outline-none hover:bg-white/10 focus:border-white"
                        >
                          {item.title}
                        </Link>
                      </li>
                    );
                  }
                })}
              </ul>
            </div>
          </div>

          <div className="col-span-6 space-y-1 md:order-3 md:col-span-8 md:grid md:grid-cols-4 md:gap-x-5 md:space-y-0">
            {groupedByAge.map(group => (
              <Menu as="div" key={group.age}>
                {({ open, close }) => (
                  <>
                    <Menu.Button className="flex w-full appearance-none items-center justify-between rounded border border-transparent bg-white/10 px-3.5 py-3 text-left text-sm font-semibold text-white outline-none focus:border-white">
                      {group.age} jaar
                      <SvgChevron
                        className={classNames(
                          "ml-2 w-2.5 text-white transition-transform",
                          open && "rotate-180 transform",
                        )}
                        aria-hidden="true"
                      />
                    </Menu.Button>

                    <Transition
                      as={Fragment}
                      enter="transition ease-out duration-100"
                      enterFrom="transform opacity-0 scale-95"
                      enterTo="transform opacity-100 scale-100"
                      leave="transition ease-in duration-75"
                      leaveFrom="transform opacity-100 scale-100"
                      leaveTo="transform opacity-0 scale-95"
                    >
                      <Menu.Items>
                        <ul className="my-3">
                          {group.pages.map(page => (
                            <li key={page.id}>
                              <Link
                                href={`/${page.ageCategory?.title}/${page.slug}`}
                                onClick={close}
                                className="flex w-full appearance-none items-center justify-between rounded border border-transparent px-3.5 py-3 text-left text-sm font-semibold text-white outline-none hover:bg-white/10 focus:border-white"
                              >
                                {page.title}
                              </Link>
                            </li>
                          ))}
                        </ul>
                      </Menu.Items>
                    </Transition>
                  </>
                )}
              </Menu>
            ))}
          </div>
        </div>

        <div className="mt-20 border-t border-white/10 py-8 text-center">
          <span className="mb-4 block font-heading text-xs text-white/60">Gerealiseerd door</span>
          <a
            href="https://lightbase.nl"
            target="_blank"
            rel="noreferrer noopener"
            className="inline-block rounded focus:ring focus:ring-offset-2 focus:ring-offset-darkblue"
          >
            <SvgLightbase className="mx-auto w-32" role="img" aria-label="Logo Lightbase" />
            <span className="sr-only">Ga naar de website van Lightbase</span>
          </a>
        </div>
      </div>
    </footer>
  );
}
