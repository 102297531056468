export { default as SvgGhostIcon } from "./GhostIcon";
export { default as SvgAlzheimercentrum } from "./Alzheimercentrum";
export { default as SvgAward } from "./Award";
export { default as SvgBlob } from "./Blob";
export { default as SvgBook } from "./Book";
export { default as SvgChevron } from "./Chevron";
export { default as SvgClock } from "./Clock";
export { default as SvgCross } from "./Cross";
export { default as SvgDonateQr } from "./DonateQr";
export { default as SvgEmailSent } from "./EmailSent";
export { default as SvgGhostColor } from "./GhostColor";
export { default as SvgGhostSparks } from "./GhostSparks";
export { default as SvgGhost } from "./Ghost";
export { default as SvgHamburger } from "./Hamburger";
export { default as SvgHeart } from "./Heart";
export { default as SvgHouse } from "./House";
export { default as SvgInfo } from "./Info";
export { default as SvgLightbase } from "./Lightbase";
export { default as SvgMagnifier } from "./Magnifier";
export { default as SvgParents } from "./Parents";
export { default as SvgPencil } from "./Pencil";
export { default as SvgPlay } from "./Play";
export { default as SvgQuestionmark } from "./Questionmark";
export { default as SvgShield } from "./Shield";
export { default as SvgStar } from "./Star";
export { default as SvgThumbdown } from "./Thumbdown";
export { default as SvgThumbup } from "./Thumbup";
export { default as SvgTopbarLogo } from "./TopbarLogo";
