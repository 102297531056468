import * as React from "react";
import type { SVGProps } from "react";

function SvgAward(props: SVGProps<SVGSVGElement>) {
  return (
    <svg viewBox="0 0 32 32" fill="none" role="img" {...props}>
      <path
        d="M30.24 1.92H26.56V0.32C26.56 0.144 26.416 0 26.24 0H5.76C5.584 0 5.44 0.144 5.44 0.32V1.92H1.76C1.29322 1.92 0.845556 2.10543 0.515492 2.43549C0.185428 2.76556 0 3.21322 0 3.68V9.6C0 12.868 2.4 15.584 5.528 16.08C6.144 20.728 9.88 24.392 14.56 24.9V29.108H6.72C6.012 29.108 5.44 29.68 5.44 30.388V31.68C5.44 31.856 5.584 32 5.76 32H26.24C26.416 32 26.56 31.856 26.56 31.68V30.388C26.56 29.68 25.988 29.108 25.28 29.108H17.44V24.9C22.12 24.392 25.856 20.728 26.472 16.08C29.6 15.584 32 12.868 32 9.6V3.68C32 3.21322 31.8146 2.76556 31.4845 2.43549C31.1544 2.10543 30.7068 1.92 30.24 1.92ZM5.44 13.104C3.956 12.628 2.88 11.236 2.88 9.6V4.8H5.44V13.104ZM29.12 9.6C29.12 11.24 28.044 12.632 26.56 13.104V4.8H29.12V9.6Z"
        fill="currentColor"
      />
    </svg>
  );
}

export default SvgAward;
