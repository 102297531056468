import * as React from "react";
import type { SVGProps } from "react";

function SvgGhostIcon(props: SVGProps<SVGSVGElement>) {
  return (
    <svg viewBox="0 0 11 10" fill="none" role="img" {...props}>
      <path
        d="M3.53289 0.0371724C3.78277 0.0125939 4.02737 0 4.25944 0C4.80776 0 5.29411 0.068454 5.70551 0.203128C6.05455 0.317681 6.33835 0.47801 6.35215 0.485803L6.35229 0.485883L6.35493 0.487508L6.35757 0.489132C6.54063 0.59679 6.98456 0.867561 7.15071 1.05484C7.48168 1.4284 7.63793 1.70039 7.67265 1.96445C7.71132 2.25805 7.591 2.47148 7.48489 2.65972L7.48475 2.65996L7.48469 2.66007C7.33768 2.92087 7.15468 3.2455 7.30059 4.08694L7.30213 4.09547L7.30323 4.104C7.33168 4.32877 7.53432 4.95896 7.69793 5.46775L7.69991 5.4739L7.70174 5.47962C7.82963 5.87766 7.9503 6.25327 8.01329 6.50153C8.05966 6.68454 8.10274 6.70262 8.15285 6.72354C8.22647 6.75442 8.30053 6.78387 8.37877 6.81495L8.38882 6.81896C8.49734 6.86219 8.60946 6.90687 8.72138 6.95613C9.24333 7.18546 9.60705 7.45277 9.86571 7.79728C10.2494 8.30815 10.3578 8.84075 10.1872 9.38046C10.0927 9.67987 9.8633 9.88625 9.55782 9.94678C9.50354 9.95755 9.4486 9.96303 9.39453 9.96303C9.21828 9.96303 8.96137 9.90595 8.73897 9.63376C8.70446 9.59151 8.65941 9.53951 8.6159 9.51128C8.52074 9.44932 8.42799 9.41926 8.33262 9.41926C8.27218 9.41926 8.20669 9.43124 8.13834 9.45501C8.07065 9.47857 8.00033 9.50782 7.92583 9.5389C7.8199 9.58318 7.71023 9.62889 7.58826 9.66667C6.86567 9.89092 6.18439 10 5.50575 10C5.34927 10 5.19148 9.99391 5.03654 9.98192C3.91507 9.89559 2.93842 9.52204 2.13407 8.87183C1.72816 8.54377 1.38093 8.15824 1.10204 7.72598C0.86096 7.35243 0.663828 6.93297 0.515485 6.47755C0.354752 6.03873 0.0320178 4.98806 0.0269419 4.29596C-0.0519546 3.60228 0.0425456 2.94922 0.308904 2.35243C0.606249 1.68576 1.02359 1.15113 1.54927 0.763356C2.10001 0.3571 2.76722 0.112736 3.53289 0.0371724Z"
        fill="currentColor"
      />
    </svg>
  );
}

export default SvgGhostIcon;
