import * as React from "react";
import type { SVGProps } from "react";

function SvgThumbdown(props: SVGProps<SVGSVGElement>) {
  return (
    <svg viewBox="0 0 26 32" fill="none" role="img" {...props}>
      <path
        d="M10.0885 29.0156C10.2208 30.921 12.0264 32.3927 13.9724 31.9062L14.5037 31.7739C14.9911 31.66 15.4392 31.4178 15.8014 31.0724C16.1637 30.727 16.4269 30.2909 16.5638 29.8095C17.0116 28.1484 17.6488 24.696 16.69 20.6289C16.9668 20.6696 17.2701 20.7042 17.5918 20.7327C19.0432 20.865 20.9892 20.8772 22.7134 20.3032C23.7678 19.951 24.7368 18.919 25.1561 17.7139C25.366 17.1305 25.4324 16.5052 25.3494 15.8908C25.2665 15.2764 25.0368 14.691 24.6798 14.1841C24.7978 13.9439 24.8894 13.6915 24.9607 13.4473C25.1174 12.8976 25.1907 12.291 25.1907 11.7048C25.1907 11.1144 25.1174 10.5119 24.9607 9.96025C24.8808 9.67627 24.7716 9.40134 24.635 9.1399C24.979 8.35212 24.8528 7.4707 24.6289 6.80098C24.3995 6.14039 24.0639 5.5216 23.6355 4.96893C23.7454 4.65748 23.7902 4.33178 23.7902 4.02237C23.7815 3.36949 23.6041 2.72996 23.2752 2.16589C22.5953 0.970987 21.2457 0 19.3383 0L12.2137 0C10.9821 0 10.0356 0.162849 9.22947 0.441728C8.53626 0.695538 7.87389 1.02672 7.25492 1.429L7.15721 1.48803C6.13126 2.115 5.12364 2.72976 2.94757 2.95978C1.38829 3.12466 0 4.38471 0 6.1048L0 14.2472C0 15.9796 1.39439 17.1643 2.76233 17.5368C4.49056 18.0091 5.96638 19.1388 7.10225 20.407C8.24219 21.6813 8.9628 23.0126 9.21725 23.7413C9.62234 24.9118 9.94193 26.8762 10.0885 29.0156V29.0156Z"
        fill="currentColor"
      />
    </svg>
  );
}

export default SvgThumbdown;
