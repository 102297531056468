import * as React from "react";
import type { SVGProps } from "react";

function SvgHouse(props: SVGProps<SVGSVGElement>) {
  return (
    <svg viewBox="0 0 36 32" fill="none" role="img" {...props}>
      <path
        d="M35.9875 15.9688C35.9875 17.0938 35.05 17.975 33.9875 17.975H31.9875L32.0312 27.9875C32.0312 28.1562 32.0188 28.325 32 28.4937V29.5C32 30.8813 30.8813 32 29.5 32H28.5C28.4312 32 28.3625 32 28.2938 31.9937C28.2063 32 28.1187 32 28.0312 32H24.5C23.1187 32 22 30.8813 22 29.5V24C22 22.8937 21.1063 22 20 22H16C14.8937 22 14 22.8937 14 24V29.5C14 30.8813 12.8813 32 11.5 32H8.00625C7.9125 32 7.81875 31.9937 7.725 31.9875C7.65 31.9937 7.575 32 7.5 32H6.5C5.11875 32 4 30.8813 4 29.5V22.5C4 22.4438 4 22.3813 4.00625 22.325V17.975H2C0.875 17.975 0 17.1 0 15.9688C0 15.4062 0.1875 14.9062 0.625 14.4688L16.65 0.5C17.0875 0.0625 17.5875 0 18.025 0C18.4625 0 18.9625 0.125 19.3375 0.4375L35.3 14.4688C35.8 14.9062 36.05 15.4062 35.9875 15.9688Z"
        fill="currentColor"
      />
    </svg>
  );
}

export default SvgHouse;
