import * as React from "react";
import type { SVGProps } from "react";

function SvgThumbup(props: SVGProps<SVGSVGElement>) {
  return (
    <svg viewBox="0 0 26 32" fill="none" role="img" {...props}>
      <path
        d="M10.0885 2.98438C10.2208 1.07905 12.0264 -0.392702 13.9724 0.0938094L14.5037 0.226125C14.9911 0.339983 15.4392 0.58218 15.8014 0.927593C16.1637 1.27301 16.4269 1.70905 16.5638 2.19049C17.0116 3.85155 17.6488 7.30395 16.69 11.3711C16.9668 11.3304 17.2701 11.2958 17.5918 11.2673C19.0432 11.135 20.9892 11.1228 22.7134 11.6968C23.7678 12.049 24.7368 13.081 25.1561 14.2861C25.366 14.8695 25.4324 15.4948 25.3494 16.1092C25.2665 16.7236 25.0368 17.309 24.6798 17.8159C24.7978 18.0561 24.8894 18.3085 24.9607 18.5527C25.1174 19.1024 25.1907 19.709 25.1907 20.2952C25.1907 20.8856 25.1174 21.4881 24.9607 22.0397C24.8808 22.3237 24.7716 22.5987 24.635 22.8601C24.979 23.6479 24.8528 24.5293 24.6289 25.199C24.3995 25.8596 24.0639 26.4784 23.6355 27.0311C23.7454 27.3425 23.7902 27.6682 23.7902 27.9776C23.7815 28.6305 23.6041 29.27 23.2752 29.8341C22.5953 31.029 21.2457 32 19.3383 32L12.2137 32C10.9821 32 10.0356 31.8372 9.22947 31.5583C8.53626 31.3045 7.87389 30.9733 7.25492 30.571L7.15721 30.512C6.13126 29.885 5.12364 29.2702 2.94757 29.0402C1.38829 28.8753 -3.83323e-07 27.6153 -5.33699e-07 25.8952L-1.24553e-06 17.7528C-1.39698e-06 16.0204 1.39439 14.8357 2.76232 14.4632C4.49056 13.9909 5.96638 12.8612 7.10225 11.593C8.24219 10.3187 8.9628 8.9874 9.21725 8.25865C9.62234 7.08818 9.94193 5.12381 10.0885 2.98438V2.98438Z"
        fill="currentColor"
      />
    </svg>
  );
}

export default SvgThumbup;
