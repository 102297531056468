import * as React from "react";
import type { SVGProps } from "react";

function SvgParents(props: SVGProps<SVGSVGElement>) {
  return (
    <svg viewBox="0 0 16 16" fill="none" role="img" {...props}>
      <path
        d="M4 5.89474C4.53043 5.89474 5.03914 5.67293 5.41421 5.27812C5.78929 4.88331 6 4.34782 6 3.78947C6 3.23112 5.78929 2.69564 5.41421 2.30083C5.03914 1.90601 4.53043 1.68421 4 1.68421C3.46957 1.68421 2.96086 1.90601 2.58579 2.30083C2.21071 2.69564 2 3.23112 2 3.78947C2 4.34782 2.21071 4.88331 2.58579 5.27812C2.96086 5.67293 3.46957 5.89474 4 5.89474ZM4 7.57895C3.52724 7.57895 3.05911 7.48093 2.62234 7.29049C2.18557 7.10005 1.78871 6.82092 1.45442 6.46904C1.12012 6.11715 0.854951 5.6994 0.674034 5.23964C0.493117 4.77988 0.4 4.28711 0.4 3.78947C0.4 3.29183 0.493117 2.79907 0.674034 2.3393C0.854951 1.87954 1.12012 1.4618 1.45442 1.10991C1.78871 0.758026 2.18557 0.478895 2.62234 0.288457C3.05911 0.0980177 3.52724 -7.41543e-09 4 0C4.95478 1.49761e-08 5.87045 0.399247 6.54558 1.10991C7.22072 1.82058 7.6 2.78444 7.6 3.78947C7.6 4.7945 7.22072 5.75837 6.54558 6.46904C5.87045 7.1797 4.95478 7.57895 4 7.57895ZM6.4 16V12.6316C6.4 11.9616 6.14714 11.319 5.69706 10.8452C5.24697 10.3714 4.63652 10.1053 4 10.1053C3.36348 10.1053 2.75303 10.3714 2.30294 10.8452C1.85286 11.319 1.6 11.9616 1.6 12.6316V16H0V12.6316C0 11.5149 0.421427 10.4439 1.17157 9.65429C1.92172 8.86466 2.93913 8.42105 4 8.42105C5.06087 8.42105 6.07828 8.86466 6.82843 9.65429C7.57857 10.4439 8 11.5149 8 12.6316V16H6.4Z"
        fill="currentColor"
        fillOpacity={0.3}
      />
      <path
        d="M12.4 9.26314C12.8244 9.26314 13.2314 9.0857 13.5314 8.76985C13.8315 8.454 14 8.02561 14 7.57893C14 7.13225 13.8315 6.70387 13.5314 6.38802C13.2314 6.07216 12.8244 5.89472 12.4 5.89472C11.9757 5.89472 11.5687 6.07216 11.2687 6.38802C10.9686 6.70387 10.8 7.13225 10.8 7.57893C10.8 8.02561 10.9686 8.454 11.2687 8.76985C11.5687 9.0857 11.9757 9.26314 12.4 9.26314ZM12.4 10.9474C11.5514 10.9474 10.7374 10.5925 10.1373 9.96076C9.53719 9.32906 9.20005 8.47229 9.20005 7.57893C9.20005 6.68557 9.53719 5.8288 10.1373 5.1971C10.7374 4.5654 11.5514 4.21051 12.4 4.21051C13.2487 4.21051 14.0627 4.5654 14.6628 5.1971C15.2629 5.8288 15.6 6.68557 15.6 7.57893C15.6 8.47229 15.2629 9.32906 14.6628 9.96076C14.0627 10.5925 13.2487 10.9474 12.4 10.9474ZM14.4 16V15.5789C14.4 15.0206 14.1893 14.4851 13.8143 14.0903C13.4392 13.6955 12.9305 13.4737 12.4 13.4737C11.8696 13.4737 11.3609 13.6955 10.9858 14.0903C10.6108 14.4851 10.4 15.0206 10.4 15.5789V16H8.80005V15.5789C8.80005 15.0813 8.89317 14.5885 9.07408 14.1288C9.255 13.669 9.52017 13.2513 9.85446 12.8994C10.1888 12.5475 10.5856 12.2684 11.0224 12.0779C11.4592 11.8875 11.9273 11.7895 12.4 11.7895C12.8728 11.7895 13.3409 11.8875 13.7777 12.0779C14.2145 12.2684 14.6113 12.5475 14.9456 12.8994C15.2799 13.2513 15.5451 13.669 15.726 14.1288C15.9069 14.5885 16 15.0813 16 15.5789V16H14.4Z"
        fill="currentColor"
      />
    </svg>
  );
}

export default SvgParents;
